<template>
  <div class="content">
    <fade-transition :duration="100" mode="out-in">
      <router-view></router-view>
    </fade-transition>

    <div class="row">
      <div class="col-lg-3 col-sm-6">
        <a href="/candidat/email">
          <div class="card-box bg-primary">
            <div class="inner">
              <h3> {{ linksCount }} </h3>
              <p>Liens créés </p>
            </div>
            <div class="icon">
              <i class="fa-solid fa-link"></i>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import {FadeTransition} from 'vue2-transitions';
import {axiosApiInstance} from "@/axiosInterceptor";

export default {
  components: {
    FadeTransition
  },
  data() {
    return {
      linksCount: 0
    }
  },
  mounted() {
    this.getLinkCount()
  },
  methods: {
    getLinkCount() {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/way-links-count').then(response => {
        if (response.data) {
          this.linksCount = response.data.count
        }
      }).catch(() => {
        this.updateFlash("Une erreur est survenue.", 'danger')
      })
    }
  }
};
</script>
<style scoped>

.card-box {
  position: relative;
  color: #fff;
  padding: 20px 10px 40px;
  margin: 20px 0px;
  border-radius: 20px;
}

.card-box:hover {
  text-decoration: none;
}

.card-box:hover .icon i {
  font-size: 100px;
  transition: 1s;
  -webkit-transition: 1s;
}

.card-box .inner {
  padding: 5px 10px 0 10px;
}

.card-box h3 {
  color: #fff;
  font-size: 27px;
  font-weight: bold;
  margin: 0 0 8px 0;
  white-space: nowrap;
  padding: 0;
  text-align: left;
}

.card-box p {
  font-size: 15px;
}

.card-box .icon {
  position: absolute;
  top: auto;
  bottom: 5px;
  right: 5px;
  z-index: 0;
  font-size: 72px;
  color: rgba(0, 0, 0, 0.15);
}

.card-box .card-box-footer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  text-align: center;
  padding: 3px 0;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  text-decoration: none;
}

.card-box:hover .card-box-footer {
  background: rgba(0, 0, 0, 0.3);
}
</style>