<script>
export default {
  props: {
    user: Object,
    persist: Function
  },
  data() {
    return {
      errors: [],
      countries: [
        'France',
        'Belgique'
      ],
    }
  },
  methods: {
    checkUserForm(event) {
      this.errors = []
      event.target.classList.add('was-validated')

      if (!this.user.lastname) {
        this.errors.push('Nom requis.');
      }
      if (!this.user.firstname) {
        this.errors.push('Prénom requis.');
      }
      if (!this.user.email) {
        this.errors.push('Email requis.');
      }
      if (!this.user.phoneNumber) {
        this.errors.push('Numéro de téléphone requis.');
      }
      if (!this.user.password) {
        this.errors.push('Mot de passe requis.');
      }
      if (!this.user.passwordConfirm) {
        this.errors.push('Confirmation du mot de passe requis.');
      }

      if (this.errors.length === 0) {
        this.persist()
      }

    },
  }
}
</script>

<template>
  <div class="container">
    <form action="#" @submit.prevent="checkUserForm($event)" method="post">
      <p class="alert alert-danger" v-if="errors.length">
        <b>Merci de corriger les erreurs suivantes:</b>
        <ul>
          <li v-for="error in errors">{{ error }}</li>
        </ul>
      </p>
      <div class="row">
        <div class="col lg">
          <div class="mb-3">
            <label for="name" class="form-label">Nom*</label>
            <input type="text" class="form-control" id="name" placeholder="Nom" v-model="user.lastname" required>
            <div class="invalid-feedback">
              Ce champ ne peut être vide.
            </div>
          </div>
          <div class="mb-3">
            <label for="firstname" class="form-label">Prénom*</label>
            <input type="text" class="form-control" id="firstname" placeholder="Prénom" v-model="user.firstname"
                   required>
            <div class="invalid-feedback">
              Ce champ ne peut être vide.
            </div>
          </div>
          <div class="mb-3">
            <label for="phone" class="form-label">Téléphone*</label>
            <input type="tel" class="form-control" id="phone" placeholder="0600000000" v-model="user.phoneNumber"
                   required pattern="\d{10,10}">
            <div class="invalid-feedback">
              Merci de saisir un numéro de téléphone valide au format 0600000000.
            </div>
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Email*</label>
            <input type="email" class="form-control" id="email" placeholder="John@doe.com" v-model="user.email" required
                   pattern="[\w\-\.]+@([\w\-]+\.)+[\w\-]{2,4}">
            <div class="invalid-feedback">
              Merci de saisir un email valide.
            </div>
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Mot de passe*</label>
            <input type="password" class="form-control" id="password" v-model="user.password" required>
            <div class="invalid-feedback">
              Ce champ ne peut être vide.
            </div>
          </div>
          <div class="mb-3">
            <label for="passwordConfirm" class="form-label">Confirmation mot de passe*</label>
            <input type="password" class="form-control" id="passwordConfirm" v-model="user.passwordConfirm" required>
            <div class="invalid-feedback">
              Ce champ ne peut être vide.
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="form-check">
            <label class="form-check-label" for="rgpdConsent">
              *En cochant cette case, j'accepte les mentions légales et politiques de confidentialités.
            </label>
            <input class="form-check-input" type="checkbox" value="" id="rgpdConsent" v-model="user.gdprApproved"
                   required>
            <div class="invalid-feedback">
              Nous avons besoin de votre consentement pour vous inscire.
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Valider</button>
      </div>
    </form>
  </div>
</template>

<style scoped>

</style>