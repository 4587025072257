<template>
  <div class="sidebar">
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo">
        <div class="simple-text logo-normal">
          {{ title }}
        </div>
      </div>
      <slot>

      </slot>
      <ul class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->

        <!--        <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36"/>-->
        <!--        <sidebar-link to="/campaign" :name="$t('sidebar.campaign')" icon="tim-icons icon-bullet-list-67"/>-->
        <!--        <sidebar-link to="/email" :name="$t('sidebar.mailingList')" icon="tim-icons icon-bullet-list-67"/>-->
        <sidebar-link to="/candidat/email" :name="$t('sidebar.candidate')" icon="tim-icons icon-single-02"/>
        <!--        <sidebar-link to="#" :name="$t('sidebar.subscription')" icon="tim-icons icon-coins"/>-->
        <!--        <slot name="links">-->
        <!--          <sidebar-link v-for="(link,index) in sidebarLinks"-->
        <!--                        :key="index"-->
        <!--                        :to="link.path"-->
        <!--                        :name="link.name"-->
        <!--                        :icon="link.icon">-->
        <!--          </sidebar-link>-->
        <!--        </slot>-->
      </ul>
    </div>
  </div>
</template>
<script>
import SidebarLink from "./SidebarLink";

export default {
  props: {
    title: {
      type: String,
      default: "SOMEWAY"
    },
    backgroundColor: {
      type: String,
      default: "vue"
    },
    activeColor: {
      type: String,
      default: "success",
      validator: value => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger"
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
      addLink: this.addLink,
      removeLink: this.removeLink
    };
  },
  components: {
    SidebarLink
  },
  computed: {
    /**
     * Styles to animate the arrow near the current active sidebar link
     * @returns {{transform: string}}
     */
    arrowMovePx() {
      return this.linkHeight * this.activeLinkIndex;
    },
    shortTitle() {
      return this.title.split(' ')
          .map(word => word.charAt(0))
          .join('').toUpperCase();
    }
  },
  data() {
    return {
      linkHeight: 65,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
      links: []
    };
  },
  methods: {
    findActiveLink() {
      this.links.forEach((link, index) => {
        if (link.isActive()) {
          this.activeLinkIndex = index;
        }
      });
    },
  },
  mounted() {
    this.$watch("$route", this.findActiveLink, {
      immediate: true
    });
  }
};
</script>
<style lang="scss" scoped>
.sidebar {
  background: transparent linear-gradient(0deg, #FF5D00 0%, #C700FF 100%) 0% 0% no-repeat padding-box !important;

  .nav-link p {
    color: white !important;
    font-size: 0.9rem !important;
  }
}

</style>
