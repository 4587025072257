<script>
import TopNavbar from "@/layout/dashboard/TopNavbar.vue";
import {axiosApiInstance} from "@/axiosInterceptor";
import router from "@/router";

export default {
  name: 'candidat-email-index',
  components: {
    TopNavbar
  },
  data() {
    return {
      links: [],
      flash: null,
      isActive: false,
    }
  },
  mounted() {
    this.getLinks()
    this.checkIfCompanyIsActive()
  },
  methods: {
    getLinks() {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/way-links').then(response => {
        if (response.data) {
          this.links = response.data.links
        }
      }).catch(() => {
        this.updateFlash("Une erreur est survenue.", 'danger')
      })
    },
    newLink() {
      router.push('/candidat/email/new')
    },
    updateFlash(message, type) {
      this.flash = {message: message, type: type}
      setTimeout(() => {
        this.clearFlash()
      }, 3000)
    },
    clearFlash() {
      this.flash = null
    },
    displayModal() {
      const modal = document.querySelector('.modal')

      modal.style.display = 'block'
    },
    hiddeModal() {
      const modal = document.querySelector('.modal')

      modal.style.display = 'none'
    },
    downloadWay(id, candidateUser) {
      this.displayModal()
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/admin/company_pdf/' + id, {responseType: 'blob'}).then(response => {
        const blob = new Blob([response.data], {type: 'application/pdf'})
        const link = document.createElement('A')
        link.href = URL.createObjectURL(blob)
        link.download = 'Way entreprise ' + candidateUser + '.pdf'
        link.click()
        URL.revokeObjectURL(link.href)
        this.hiddeModal()
      }).catch(e => {
        this.updateFlash(e.message, 'danger')
      })
    },
    downloadWayCandidate(id, candidateUser) {
      this.displayModal()
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/admin/candidate_pdf/' + id, {responseType: 'blob'}).then(response => {
        const blob = new Blob([response.data], {type: 'application/pdf'})
        const link = document.createElement('A')
        link.href = URL.createObjectURL(blob)
        link.download = 'Way candidat ' + candidateUser + '.pdf'
        link.click()
        URL.revokeObjectURL(link.href)
        this.hiddeModal()
      }).catch(e => {
        this.updateFlash(e.message, 'danger')
      })
    },
    sendLinkAgain(linkId) {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/way-link-send-email-again/' + linkId).then(response => {
        if (response.data.sended) {
          this.updateFlash('Lien envoyé par email', 'success')
        }
      }).catch(e => {
        this.updateFlash('Une erreur est survenue.', 'danger')
      })
    },
    checkIfCompanyIsActive() {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/check-if-company-is-active').then(response => {
        this.isActive = !!response.data.isActive;
      }).catch(e => {
        this.updateFlash('Une erreur est survenue.', 'danger')
      })
    },
    searchInLink() {

      let field = document.getElementById('searchField')

      if (field.value !== '' && field.value !== null) {
        axiosApiInstance.post(process.env.VUE_APP_API_URL + '/way-links-research', {keyword: field.value}).then(response => {
          if (response.data) {
            this.links = response.data.links
          }
        }).catch(() => {
          this.updateFlash("Une erreur est survenue.", 'danger')
        })
      } else {
        this.getLinks()
      }
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <div class="position-fixed top-0 start-50 translate-middle-x p-3" style="z-index: 11;padding-top:33px !important;">
      <div :class="'toast bg-' + flash.type + ' ' + (flash?'show':'hide')" v-if="flash">
        <div class="d-flex">
          <div class="toast-body">
            {{ flash.message }}
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                  aria-label="Fermer" @click="clearFlash"></button>
        </div>
      </div>
    </div>

    <side-bar>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <div class="content">
        <h1>Liste de mes liens Someway</h1>
        <div class="d-flex mb-3">

          <input class="form-control mr-sm-2 w-50" type="search" id="searchField" placeholder="Recherche"
                 aria-label="Search">
          <button class="btn btn-secondary my-2 my-sm-0" type="submit" @click="searchInLink()">Recherche</button>
        </div>
        <button class="btn btn-primary my-2 my-sm-0" @click="newLink()" v-if="isActive">Nouveau lien candidat</button>
        <button class="btn btn-primary my-2 my-sm-0" v-else disabled="true">Nouveau lien candidat</button>
        <table class="table text-center">
          <thead>
          <tr>
            <th class="text-center">Date de création</th>
            <th class="text-center">Candidat</th>
            <th class="text-center">Email</th>
            <th class="text-center">Terminé ?</th>
            <th class="text-center">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in links" v-if="links.length > 0">
            <td>{{ row.createdAt }}</td>
            <td class="text-left">{{ row.candidateUser }}</td>
            <td class="text-left">{{ row.candidateEmail }}</td>
            <td>{{ row.finished ? 'Oui' : 'Non' }}</td>
            <td>
              <div class="">
                <button class="btn btn-primary mr-2" v-if="row.finished"
                        @click="downloadWay(row.way, row.candidateUser)">
                  <i class="fa-solid fa-file-arrow-down "></i> Way Entreprise
                </button>
                <button class="btn btn-primary" v-if="row.finished"
                        @click="downloadWayCandidate(row.way, row.candidateUser)">
                  <i class="fa-solid fa-file-arrow-down "></i> Way Candidat
                </button>
                <button class="btn btn-primary" v-if="!row.finished"
                        @click="sendLinkAgain(row.id)">
                  <i class="fa-solid fa-envelope"></i> Renvoyer le lien
                </button>
              </div>
            </td>
          </tr>
          <tr v-else>
            <td colspan="5">
              Aucun lien trouvé
            </td>
          </tr>
          </tbody>
        </table>

        <div class="modal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body d-flex">
                <p>
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Chargement en cours...</span>
                  </div>
                  <h3 class="ml-3 modal-title">Chargement en cours</h3>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>
.toast-body {
  width: 90%;
}

.table .btn {
  margin: 10px;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);;

}

.modal-content {
  animation: bounce 1s;
  padding: 2rem;
}

@keyframes bounce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

button[disabled] {
  background: gray;
}
</style>