<script>
import {axiosApiInstance} from "@/axiosInterceptor";
import cookie from "cookiejs";
import axios from "axios";

export default {
  name: 'mailing-edit',
  computed: {
    descriptionCounter() {
      return this.mailingList.description.length + '/255'
    }
  },
  components: {},
  props: {
    scope: String,
    mailingList: Object
  },
  data() {
    return {
      emails: null,
    }
  },
  mounted() {
    this.getCandidatesEmails()
  },
  methods: {
    getCandidatesEmails() {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/api/email_candidates').then(response => {
        this.emails = response.data['hydra:member']
        if (this.scope === 'edit') {
        }
      })
    },
    saveMailingList() {
      if (this.scope === 'new') {
        axiosApiInstance.post(process.env.VUE_APP_API_URL + '/api/mailing-lists/' ,this.mailingList).then(response =>{
          this.$router.push('/email/' + response.data.id)
        })
      } else if (this.scope === 'edit') {
        axios.patch(process.env.VUE_APP_API_URL + '/api/mailing-lists/' + this.mailingList.id, this.mailingList, {
          headers: {
            'Authorization': 'Bearer ' + cookie.get('session'),
            'Content-Type': 'application/merge-patch+json'
          }
        }).then(response => {
          this.$router.push('/email/' + this.mailingList.id)
        })
      }
    },
  }
}
</script>

<template>

  <form class="form-group" @submit.prevent="saveMailingList()">
    <div class="mb-3">
      <label for="labelInput" class="form-label">Nom de la liste d'email*</label>
      <input type="text" class="form-control" id="labelInput" aria-describedby="labelHelp"
             placeholder="Nom de la liste d'emails" v-model="mailingList.label" required>
    </div>
    <div class="mb-3">
      <label for="descriptionInput" class="form-label">Description</label>
      <textarea type="text" class="form-control" id="descriptionInput" aria-describedby="labelHelp"
                placeholder="Description (255 caractères maximum)" v-model="mailingList.description" maxlength="255"
                required></textarea>
      <div class="d-flex justify-content-between">
        <div id="labelHelp" class="form-text">{{ descriptionCounter }}</div>
      </div>
    </div>
    <div class="mb-3">
      <label for="mailingSelect" class="form-label">Associer des emails</label>
      <select id="mailingSelect" class="form-select" aria-label=".form-select-sm" required multiple
              v-model="mailingList.mailingListEmails">
        <option :value="'/api/email_candidates/' + email.id" v-for="email in emails">{{ email.email }}</option>

      </select>
    </div>
    <button type="submit" class="btn btn-primary">Valider</button>
  </form>
</template>

<style scoped>
.form-text {
  color: black;
}

.form-control {
  background-color: white;
}
</style>