import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";
import Login from "@/pages/Security/Login.vue";
import Logout from "@/pages/Security/Logout.vue";
import Signup from "@/pages/Security/Signup.vue";
import ResetPassword from "@/pages/Security/ResetPassword.vue";
import CampaignIndex from '@/pages/Campaign/index.vue'
import CampaignShow from '@/pages/Campaign/show.vue'
import CampaignEdit from '@/pages/Campaign/edit.vue'
import CampaignNew from '@/pages/Campaign/new.vue'
import MailingIndex from '@/pages/MailingList/index.vue'
import MailingShow from '@/pages/MailingList/show.vue'
import MailingEdit from '@/pages/MailingList/edit.vue'
import MailingNew from '@/pages/MailingList/new.vue'
import CandidatEmailNew from '@/pages/CandidatEmail/new.vue'
import CandidatEmailIndex from '@/pages/CandidatEmail/index.vue'

const routes = [
  {
    path: "/",
    component: Login,
    name: "login",
  },
  {
    path: "/dashboard",
    name:'dashboard',
    component: DashboardLayout,
  },
  {
    path: "/campaign",
    name:'campaign-index',
    component: CampaignIndex,
  },
  {
    path: "/campaign/new",
    name:'campaign-new',
    component: CampaignNew,
  },
  {
    path: "/campaign/:id",
    name:'campaign-show',
    component: CampaignShow,
  },
  {
    path: "/campaign/edit/:id",
    name:'campaign-edit',
    component: CampaignEdit,
  },
  {
    path: "/email",
    name:'mailing-index',
    component: MailingIndex,
  },
  {
    path: "/email/:id",
    name:'mailing-show',
    component: MailingShow,
  },
  {
    path: "/email/new",
    name:'mailing-new',
    component: MailingNew,
  },
  {
    path: "/email/edit/:id",
    name:'mailing-edit',
    component: MailingEdit,
  },
  {
    path: "/logout",
    name:'logout',
    component: Logout,
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/candidat/email/new',
    name: 'candidat-email-new',
    component: CandidatEmailNew
  },
  {
    path: '/candidat/email',
    name: 'candidat-email-index',
    component: CandidatEmailIndex
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
