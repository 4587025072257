<template>
  <div>
    <notifications></notifications>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>

import {useRoute, useRouter} from "vue-router/composables";
import {onMounted, watch} from "vue";
import cookie from "cookiejs";

export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const excludedRoute = [
      '/reset-password',
      '/signup',
      '/'
    ]

    onMounted(async () => {
      if (!excludedRoute.includes(route.path)) {
        cookie.get('session') === false ? await router.push('/') : null
      }
    })
  },

  methods: {
    disableRTL() {
      if (!this.$rtl.isRTL) {
        this.$rtl.disableRTL();
      }
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName('html')[0];
      root.classList.toggle('nav-open');
    }
  },
  mounted() {
    this.$watch('$route', this.disableRTL, {immediate: true});
    this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
  },
  watch: {
    '$route'() {
      window.scrollTo(0,0)
    }
  }

};
</script>

<style lang="scss"></style>
