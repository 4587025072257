<script>
import {axiosApiInstance} from "@/axiosInterceptor";
import cookie from "cookiejs";
import axios from "axios";

export default {
  name: 'campaign-edit',
  computed: {
    descriptionCounter() {
      return this.campaign.description.length + '/255'
    }
  },
  components: {},
  props: {
    scope: String,
    campaign: Object
  },
  data() {
    return {
      mailingLists: null,
    }
  },
  mounted() {
    this.getmailingLists()
  },
  methods: {
    getmailingLists() {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/api/my-mailing-lists').then(response => {
        this.mailingLists = response.data.mailingLists
        if (this.scope === 'edit') {
          let currentMailingList = this.campaign.mailingList
          this.campaign.mailingList = currentMailingList['@id']
        }
      })
    },
    saveCampaign() {
      if (this.scope === 'new') {
        console.log(this.campaign);

        axiosApiInstance.post(process.env.VUE_APP_API_URL + '/api/recruitment_campaigns' ,this.campaign).then(response =>{
          this.$router.push('/campaign/' + response.data.id)
        })
      } else if (this.scope === 'edit') {
        axios.patch(process.env.VUE_APP_API_URL + '/api/recruitment_campaigns/' + this.campaign.id, this.campaign, {
          headers: {
            'Authorization': 'Bearer ' + cookie.get('session'),
            'Content-Type': 'application/merge-patch+json'
          }
        }).then(response => {
          this.$router.push('/campaign/' + this.campaign.id)
        })
      }
    }
  }
}
</script>

<template>

  <form class="form-group" @submit.prevent="saveCampaign()">
    <div class="mb-3">
      <label for="labelInput" class="form-label">Nom de la campagne*</label>
      <input type="text" class="form-control" id="labelInput" aria-describedby="labelHelp"
             placeholder="Nom de la campagne" v-model="campaign.label" required>
      <div id="labelHelp" class="form-text">Le nom de la campagne sera visible par les candidats.</div>
    </div>
    <div class="mb-3">
      <label for="descriptionInput" class="form-label">Description*</label>
      <textarea type="text" class="form-control" id="descriptionInput" aria-describedby="labelHelp"
                placeholder="Description (255 charactères maximum)" v-model="campaign.description" maxlength="255"
                required></textarea>
      <div class="d-flex justify-content-between">
        <div id="labelHelp" class="form-text">La description sera visible par les candidats.</div>
        <div id="labelHelp" class="form-text">{{ descriptionCounter }}</div>
      </div>
    </div>
    <div class="mb-3">
      <label for="mailingSelect" class="form-label">Associer une liste d'email*</label>
      <select id="mailingSelect" class="form-select form-select-sm" aria-label=".form-select-sm" required
              v-model="campaign.mailingList">
        <option :value="'/api/mailing_lists/' + list.id" v-for="list in mailingLists">{{ list.label }}</option>

      </select>
    </div>
    <button type="submit" class="btn btn-primary">Valider</button>
  </form>
</template>

<style scoped>
.form-text {
  color: black;
}

.form-control {
  background-color: white;
}
</style>