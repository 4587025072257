<script>
import axios from "axios";
import cookie from "cookiejs";
import VueJwtDecode from 'vue-jwt-decode';
import router from "@/router";

export default {
  data() {
    return {
      email: null,
      password: null,
      errors: [],
    }
  },
  methods: {
    checkFormLogin(e) {
      e.preventDefault();
      if (this.password && this.email) {

        const data = {
          "username": this.email,
          "password": this.password
        }

        axios.post(process.env.VUE_APP_API_URL + '/login_check_entreprise', data).then(response => {
          let tokenData = VueJwtDecode.decode(response.data.token);
          cookie.set('session', response.data.token)
          cookie.set('refresh', response.data.refresh_token)
          router.push('/dashboard')
        }).catch(error => {
          if (error.response.status === 401) {
            this.errors.push('Identifiant ou mot de passe incorrect')
          } else {
            this.errors.push('Une erreur est survenue, veuillez ressayer.')
          }
        })
      }

      if (!this.password) {
        this.errors.push('Mot de passe requis.');
      }
      if (!this.email) {
        this.errors.push('Email requis.');
      }
    },
    showHidePassword(){
      let field = document.querySelector('#passwordField')

      field.type === 'text' ? field.type = 'password' : field.type = 'text'
    }
  }
}
</script>

<template>
  <div class="container">,
    <div class="mt-5 text-center mb-5">
      <img src="/img/logo-way.svg" alt="logo-connection-someway" class="logo-connection">
    </div>
    <h1 class="text-center">Connexion</h1>
    <form @submit="checkFormLogin($event)" action="" method="post">
      <p class="alert alert-danger" v-if="errors.length">
        <b>Merci de corriger les erreurs suivantes:</b>
        <ul>
          <li v-for="error in errors">{{ error }}</li>
        </ul>
      </p>
      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <input type="email" class="form-control" id="email" placeholder="john@doe.fr" v-model="email">
      </div>

      <label for="password" class="form-label">Mot de passe</label>
      <div class="input-group mb-3">
        <input type="password" class="form-control"  id="passwordField" placeholder="Mot de passe" v-model="password">
        <div class="input-group-append" id="password">
          <span class="input-group-text" id="basic-addon2" @click="showHidePassword()"><i class="fa-regular fa-eye"></i></span>
        </div>
      </div>
      <button type="submit" class="btn btn-primary mb-5">Valider</button>
      <div>
        <p class="text-center">
          <!--          <a href="/reset-password" class="text-center">-->
          <!--            Mot de passe oublié ?-->
          <!--          </a>-->
        </p>
        <p class="text-center">
          <!--          <a href="/signup" class="text-center">-->
          <!--            Créer un compte-->
          <!--          </a>-->
        </p>

      </div>
    </form>
  </div>
</template>

<style scoped>
.fa-eye{
  color: black;
  cursor: pointer;
}
.input-group-text {
  padding: 10px 10px 10px 10px !important;
  border-color: rgba(29, 37, 59, 0.5) !important;
}

#password{
  border: none;
}
</style>