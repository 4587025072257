<script>
import ForgetPasswordForm from "@/components/ForgetPasswordForm.vue";

export default {
  components: {
    ForgetPasswordForm
  }
}
</script>

<template>
  <div class="container">
    <div class="mb-3">
      <a href="/">Retour à la connexion</a>
    </div>
    <h1 class="text-center">Mot de passe oublié</h1>
    <ForgetPasswordForm/>
  </div>
</template>

<style scoped>

</style>