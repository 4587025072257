<script>
import TopNavbar from "@/layout/dashboard/TopNavbar.vue";
import {axiosApiInstance} from "@/axiosInterceptor";
import router from "@/router";

export default {
  name: 'campaign-index',
  components: {
    TopNavbar
  },
  data() {
    return {
      campaigns: null
    }
  },
  mounted() {
    this.getCampaigns()
  },
  methods: {
    getCampaigns() {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/api/my-campaigns').then(response => {
        this.campaigns = response.data.campaigns
      })
    },
    showCampaign(id){
      router.push('/campaign/' + id)
    },
    editCampaign(id){
      router.push('/campaign/edit/' + id)
    },
    newCampaign(){
      router.push('/campaign/new')
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <side-bar>

    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="content">
        <h1>Mes campagnes</h1>

        <button class="btn btn-primary mb-5" @click="newCampaign()">Nouvelle campagne</button>
        <table class="table">
          <thead>
          <th>#</th>
          <th>Nom de la campagne</th>
          <th>Description</th>
          <th>Actions</th>
          </thead>
          <tbody>
          <tr v-for="campaign in campaigns" v-if="campaigns">
            <td>{{ campaign.id }}</td>
            <td>{{ campaign.label }}</td>
            <td>{{ campaign.description }}</td>
            <td>
              <div class="d-flex justify-content-around">
                <i class="fa-solid fa-eye" @click="showCampaign(campaign.id)"></i>
                <i class="fa-solid fa-pen" @click="editCampaign(campaign.id)"></i>
                <i class="fa-solid fa-circle-stop"></i>
              </div>
            </td>
          </tr>
          <tr v-else>
            <td colspan="4">
              Aucune campagne trouvée
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fa-solid {
  cursor: pointer;
}
</style>