<script>
import TopNavbar from "@/layout/dashboard/TopNavbar.vue";
import {axiosApiInstance} from "@/axiosInterceptor";
import campaign from "./index.vue";
import router from "@/router";

export default {
  name: 'campaign-show',
  components: {
    TopNavbar
  },
  data() {
    return {
      campaign: null
    }
  },
  mounted() {
    this.getCampaign()
  },
  methods: {
    getCampaign() {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/api/recruitment_campaigns/' + this.$route.params.id).then(response => {
        this.campaign = response.data
      })
    },
    editCampaign(id){
      router.push('/campaign/edit/' + id)
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <side-bar>

    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="content">
        <h1>Ma campagne n° {{ $route.params.id }}</h1>

        <div class="mb-5">
          <button class="btn btn-primary" @click="editCampaign($route.params.id)">Modifier</button>
          <button class="btn btn-danger">Clore la campagne</button>
        </div>

        <table class="table" v-if="campaign">
          <tr>
            <th>Nom de la campagne</th>
            <td>{{ campaign.label }}</td>
          </tr>
          <tr>
            <th>Description</th>
            <td>{{ campaign.description }}</td>
          </tr>
          <tr>
            <th>Candidatures</th>
            <td>
              {{ campaign.ways.length }}
            </td>
          </tr>
          <tr>
            <th>Mailing Liste</th>
            <td v-if="campaign.mailingList">
              {{ campaign.mailingList.label }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>