<template>
  <div class="wrapper">
    <side-bar>

    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="modal fade" id="gdprModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <p>
                En appuyant sur le bouton "Accepter", vous acceptez que les données personnelles présentes dans cette
                application soient recueillies et traitées par SOMEWAY conformément à sa politique de confidentialité.
              </p>
              <a href="/Données_personnelles_SOMEWAY.pdf" target="_blank">
                Pour toutes informations complémentaires, consulter notre politique de confidentialité sur les données à
                caractère personnel
              </a>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="logout()">Déconnexion</button>
              <button type="button" class="btn btn-primary" @click="saveGDPRApproval()">Accepter</button>
            </div>
          </div>
        </div>
      </div>


      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>


      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import axios from "axios";
import {axiosApiInstance} from "@/axiosInterceptor";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  mounted() {
    this.checkIfGDPRIsValidated()
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    checkIfGDPRIsValidated() {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/check-if-gdpr-is-validated').then(response => {
        if (!response.data.gdprValidated) {
          this.displayGDPRModal()
        }
      })
    },
    displayGDPRModal() {
      let modal = document.querySelector("#gdprModal")
      modal.classList.add('show')
      modal.style.display = 'block'
    },
    closeGDPRModal(){
      let modal = document.querySelector("#gdprModal")
      modal.classList.remove('show')
      modal.style.display = 'none'
    },
    logout(){
      this.$router.push('/logout')
    },
    saveGDPRApproval(){
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/save-gdpr-approval').then(response => {
        if (response.data.saved) {
          this.closeGDPRModal()
        }
      })
    }
  }
};
</script>
