<script>
import TopNavbar from "@/layout/dashboard/TopNavbar.vue";
import {axiosApiInstance} from "@/axiosInterceptor";
import MailingListForm from "@/pages/MailingList/_form.vue";
export default {
  name: 'mailing-edit',
  components: {
    TopNavbar,
    MailingListForm
  },
  data() {
    return {
      mailingList: null
    }
  },
  mounted() {
    this.getMailingList()
  },
  methods: {
    getMailingList() {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/api/mailing_lists/' + this.$route.params.id).then(response => {
        this.mailingList = response.data
      })
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <side-bar>

    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="content">
        <h1>Modifier la liste d'emails n° {{ $route.params.id }}</h1>
        <MailingListForm :mailingList="mailingList" v-if="mailingList" :scope="'edit'"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>