<script>
import TopNavbar from "@/layout/dashboard/TopNavbar.vue";
import {axiosApiInstance} from "@/axiosInterceptor";
import router from "@/router";

export default {
  name: 'mailing-index',
  components: {
    TopNavbar
  },
  data() {
    return {
      mailingLists: null
    }
  },
  mounted() {
    this.getMailingLists()
  },
  methods: {
    getMailingLists() {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/api/my-mailing-lists').then(response => {
        this.mailingLists = response.data.mailingLists
      })
    },
    showCampaign(id) {
      router.push('/email/' + id)
    },
    editCampaign(id) {
      router.push('/email/edit/' + id)
    },
    newCampaign() {
      router.push('/email/new')
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <side-bar>

    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="content">
        <h1>Mes listes d'emails</h1>

        <button class="btn btn-primary mb-5" @click="newCampaign()">Nouvelle liste</button>
        <table class="table">
          <thead>
          <th>#</th>
          <th>Nom de la liste</th>
          <th>Description</th>
          <th>Actions</th>
          </thead>
          <tbody>
          <tr v-for="mailingList in mailingLists" v-if="mailingLists">
            <td>{{ mailingList.id }}</td>
            <td>{{ mailingList.label }}</td>
            <td>{{ mailingList.description }}</td>
            <td>
              <div class="d-flex justify-content-around">
                <i class="fa-solid fa-eye" @click="showCampaign(mailingList.id)"></i>
                <i class="fa-solid fa-pen" @click="editCampaign(mailingList.id)"></i>
              </div>
            </td>
          </tr>
          <tr v-else>
            <td colspan="4">
              Aucune liste d'email trouvée
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fa-solid {
  cursor: pointer;
}
</style>