<script>
import TopNavbar from "@/layout/dashboard/TopNavbar.vue";
import {axiosApiInstance} from "@/axiosInterceptor";
import CampaignForm from "@/pages/Campaign/_form.vue";
export default {
  name: 'campaign-edit',
  components: {
    TopNavbar,
    CampaignForm
  },
  data() {
    return {
      campaign: null
    }
  },
  mounted() {
    this.getCampaign()
  },
  methods: {
    getCampaign() {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/api/recruitment_campaigns/' + this.$route.params.id).then(response => {
        this.campaign = response.data
      })
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <side-bar>

    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="content">
        <h1>Modifier la campagne n° {{ $route.params.id }}</h1>
        <CampaignForm :campaign="campaign" v-if="campaign" :scope="'edit'"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>