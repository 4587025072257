<script>
import TopNavbar from "@/layout/dashboard/TopNavbar.vue";
import {axiosApiInstance} from "@/axiosInterceptor";
import mailingList from "./index.vue";
import router from "@/router";

export default {
  name: 'mailingList-show',
  components: {
    TopNavbar
  },
  data() {
    return {
      mailingList: null
    }
  },
  mounted() {
    this.getMailingList()
  },
  methods: {
    getMailingList() {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/api/mailing_lists/' + this.$route.params.id).then(response => {
        this.mailingList = response.data
      })
    },
    editMailingList(id){
      router.push('/email/edit/' + id)
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <side-bar>

    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="content">
        <h1>Ma liste d'emails n° {{ $route.params.id }}</h1>

        <div class="mb-5">
          <button class="btn btn-primary" @click="editMailingList($route.params.id)">Modifier</button>
          <button class="btn btn-danger">Supprimer la liste</button>
        </div>

        <table class="table" v-if="mailingList">
          <tr>
            <th>Nom de la liste</th>
            <td>{{ mailingList.label }}</td>
          </tr>
          <tr>
            <th>Description</th>
            <td>{{ mailingList.description }}</td>
          </tr>
          <tr>
            <th>Nombre d'emails</th>
            <td>
              {{ mailingList.mailingListEmails.length }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>