<script setup>
import cookie from "cookiejs";
import router from "@/router";

cookie.clear()
router.push('/')
</script>

<template>

</template>

<style scoped>

</style>