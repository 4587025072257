<script>
import router from "@/router";

export default {
  data() {
    return {
      email: null,
      errors: [],
    }
  },
  methods: {
    checkEmailIsValid() {
      this.errors = []

      if (!this.email) {
        this.errors.push('Email requis.');
      } else {
        router.push('/')
      }
    }
  }
}
</script>

<template>
  <div class="container">
    <p class="alert alert-danger" v-if="errors.length">
      <b>Merci de corriger les erreurs suivantes:</b>
      <ul>
        <li v-for="error in errors">{{ error }}</li>
      </ul>
    </p>
    <form action="#" @submit.prevent="checkEmailIsValid()" method="post">
      <div class="mb-3">
        <label for="email" class="form-label">Email*</label>
        <input type="email" class="form-control" id="email" placeholder="John@doe.com" v-model="email" required
               pattern="[\w\-\.]+@([\w\-]+\.)+[\w\-]{2,4}">
        <div class="invalid-feedback">
          Merci de saisir un email valide.
        </div>
      </div>
      <button type="submit" class="btn btn-primary">Envoyer l'email</button>
    </form>
  </div>
</template>

<style scoped>

</style>