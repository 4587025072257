<script>
import UserForm from "@/components/UserForm.vue";
import axios from "axios";
import cookie from "cookiejs";
import router from "@/router";

export default {
  data() {
    return {}
  },
  components: {
    UserForm
  },
  methods: {
    router() {
      return router
    },
    SaveUser() {
      axios.post(process.env.VUE_APP_API_URL + '/api/create-company-user', this.user, {
        headers: {
          'Authorization': 'Bearer ' + cookie.get('session'),
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.status === 200) {
          router.push({path: '/', query: {createAccount: 'true'}})
        }
      }).catch(e => {
            this.flash = {message: e.response.data, type: 'danger'}
          }
      )
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="mb-3">
      <a href="/">Retour à la connexion</a>
    </div>
    <h1 class="text-center">Inscription</h1>
    <UserForm :user="{}" :persist="SaveUser()"/>
  </div>
</template>

<style scoped>

</style>