<script>
import TopNavbar from "@/layout/dashboard/TopNavbar.vue";
import CampaignForm from "@/pages/Campaign/_form.vue";
import {axiosApiInstance} from "@/axiosInterceptor";

export default {
  name: 'campaign-new',
  components: {
    CampaignForm,
    TopNavbar
  },
  data() {
    return {
      user: null,
      campaign: {
        label: '',
        description: '',
      }
    }
  },
  mounted() {
    this.getCurrentUser()
  },
  methods: {
    getCurrentUser() {
      axiosApiInstance.get(process.env.VUE_APP_API_URL + '/api/me').then(response => {
        this.user = response.data
        this.campaign.company = this.user.company['@id']
      }).catch(e => {
        this.flashe = {message: e.message, type: 'danger'}
      })
    },
  }
}

</script>

<template>
  <div class="wrapper">
    <side-bar>

    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="content">
        <h1>Nouvelle campagne</h1>
        <CampaignForm :campaign="campaign" v-if="campaign" :scope="'new'"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>