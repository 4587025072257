import axios from "axios";
import cookie from "cookiejs";

export const axiosApiInstance = axios.create()


//REQUEST INTERCEPTOR FOR EACH API CALL
axiosApiInstance.interceptors.request.use(
    config => {
        config.headers = {
            'Authorization': 'Bearer ' + cookie.get('session'),
            'Accept': 'application/ld+json',
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
)

// RESPONSE INTERCEPTOR FOR EACH API CALL
axiosApiInstance.interceptors.response.use(
    (response) => {
        return response
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            refreshAccessToken(originalRequest)
        }
        return Promise.reject(error);
    }
)

// REFRESH THE TOKEN FROM API AND RETURN ORIGINAL REQUEST
async function refreshAccessToken(originalRequest) {
    axios.post(process.env.VUE_APP_API_URL + '/token/refresh',
        {"refresh_token": cookie.get('refresh')}
    ).then(response => {
        cookie.set('session', response.data.token)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + cookie.get('session')
        axiosApiInstance(originalRequest).then(response =>{
            location.reload();
        });
    }).catch((e) => {
        location.href = '/logout'
    })
}